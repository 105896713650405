import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'


const Index = ({data}) => {
  return (
    <Layout>
      <div className="flex flex-wrap justify-around">
        {data.allMdx.edges.map(x=> 
          <Link to={x.node.slug} key={x.node.frontmatter.title}>
            <div  className="border border-black p-4 m-4 text-center">
              {x.node.frontmatter.title}
            </div>
          </Link>)}
      </div>
    </Layout>
  )
}

export const query = graphql`
query MyQuery {
  allMdx(filter: {frontmatter: {type: {eq: "category"}}}) {
    edges {
      node {
        frontmatter {
          type
          title
        }
        slug
      }
    }
  }
}

`

export default Index
